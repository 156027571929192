import React, { useEffect, useRef } from 'react';
import dojoLogo from '../../images/logo.png'

function Footer() {

  let scrollBarVisible = useRef(isScrollBarVisible());

  useEffect(() => {
    function handleResize() {
      scrollBarVisible.current = isScrollBarVisible();
    }
    window.addEventListener('resize', handleResize)
  })

  function isScrollBarVisible() {
    return document.documentElement.scrollHeight > document.documentElement.clientHeight;
  }

  return (
    <>
      {scrollBarVisible.current === false &&
        <div className="footer absolute">
          <img className="footer__image" src={dojoLogo} height="70" alt="Dojo" />
        </div>
      }
      <div className="footer relative" style={{ "opacity": `${scrollBarVisible.current ? 1 : 0}` }} >
        <img className="footer__image" src={dojoLogo} height="70" alt="Dojo" />
      </div>
    </>
  );
}

export { Footer };