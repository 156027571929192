import React from 'react';
import CurrencyFormat from 'react-currency-format';

function CapReceiptItem(props) {
  const { index, name, price, quantity, modals, setModals, isUncapturedList, clearPreviouslySelectedItems } = props;

  const isCaptureViewOpen = modals.captureView.isVisible;

  function CurrentUncaptureItemIsNotSelected() {
    return !(modals.captureView.currentSelectedItem.index === -1 || modals.captureView.uncapturedItems[index].selected === true);
  }

  function CurrentItemToCaptureIsNotSelected() {
    return !(modals.captureView.currentSelectedItem.index === -1 || modals.captureView.itemsToCapture[index].selected === true);
  }

  function updateSelectedUncapturedItem(e) {
    if (isCaptureViewOpen === true && !CurrentUncaptureItemIsNotSelected()) {
      const selectedIndex = parseInt(e.currentTarget.getAttribute('data-id'));
      clearPreviouslySelectedItems(modals.captureView.itemsToCapture);
      clearPreviouslySelectedItems(modals.captureView.uncapturedItems, index);
      const currentUncapturedItemIsSelected = modals.captureView.uncapturedItems[index].selected;
      modals.captureView.uncapturedItems[index].selected = !currentUncapturedItemIsSelected;
      modals.captureView.currentSelectedItem = { isUncapturedList: true, index: currentUncapturedItemIsSelected ? -1 : selectedIndex, name: name, price: price, quantity: quantity };
      setModals({ ...modals });
    }
  }

  function updateSelectedItemToCapture(e) {
    if (isCaptureViewOpen === true && !CurrentItemToCaptureIsNotSelected()) {
      const selectedIndex = parseInt(e.currentTarget.getAttribute('data-id'));
      clearPreviouslySelectedItems(modals.captureView.uncapturedItems);
      clearPreviouslySelectedItems(modals.captureView.itemsToCapture, index);
      const currentItemToCaptureIsSelected = modals.captureView.itemsToCapture[index].selected;
      modals.captureView.itemsToCapture[index].selected = !currentItemToCaptureIsSelected;
      modals.captureView.currentSelectedItem = { isUncapturedList: false, index: currentItemToCaptureIsSelected ? -1 : selectedIndex, name: name, price: price, quantity: quantity };
      setModals({ ...modals });
    }
  }

  function rowContent() {
    return (
      <>
        <div className="receipt__selected-product-name pull-left">{name.toUpperCase()}</div>
        <div className="receipt__selected-product-price pull-right">
          <CurrencyFormat value={parseFloat(price / 100)} prefix={'£'} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
        </div>
        <div className="receipt__selected-product-price pull-right margin-left receipt-item-quantity">
          x {quantity}
        </div>
      </>
    );
  }

  return (
    <>
      {isUncapturedList === true &&
        <div data-id={index}
          className={`receipt__selected-product__container
             ${CurrentUncaptureItemIsNotSelected() ? "disabled-button" : "removableProduct"}
             ${modals.captureView.uncapturedItems[index].selected ? "selectedRemovableProduct" : ""}`
          }
          disabled={CurrentUncaptureItemIsNotSelected()}
          onClick={(e) => updateSelectedUncapturedItem(e)}
        >
          {rowContent()}
        </div>
      }
      {isUncapturedList === false &&
        <div data-id={index}
          className={`receipt__selected-product__container
             ${CurrentItemToCaptureIsNotSelected() ? "disabled-button" : "removableProduct"}
             ${modals.captureView.itemsToCapture[index].selected ? "selectedRemovableProduct" : ""}`
          }
          disabled={CurrentItemToCaptureIsNotSelected()}
          onClick={(e) => updateSelectedItemToCapture(e)}
        >
          {rowContent()}
        </div>
      }
    </>
  );
}

export { CapReceiptItem };