import React from 'react';

import { PosItemRow } from './PosItemTile';

function PosItemSelection(props) {

  const { modals, posItems, receiptItems, setReceiptItems } = props;

  const posItemList = posItems.items.map((posItem, index) => {
    return (index % 3 === 0) ? <PosItemRow
      key={posItem.key}
      items={posItems.items.slice(index, index+3)}
      modals={modals}
      posItems={posItems}
      receiptItems={receiptItems}
      setReceiptItems={setReceiptItems}
    /> : "";
  })

  return (
    <div className="col-xs-12 col-sm-7 col-md-7 custom-products-row">
      <div className="products">
        {posItems.isVisible === true && posItemList}
      </div>
    </div>
  );
}

export { PosItemSelection };